
// Fonts
@import url('https://fonts.googleapis.com/css?family=Dosis|Open+Sans:400,600|Roboto|Encode+Sans:400,600,700|Nunito:200,600|Zilla+Slab:300,400,600,700|Montserrat:300,400,600,700"');

// Variables
@import 'variables';
@import 'helpers';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

html, body {
    background-color: white; //#F1F1EF;
    color: #636b6f;
    font-family: 'Open Sans', 'Encode Sans', 'Nunito', sans-serif;
    font-weight: 400;
    height: 100vh;
    letter-spacing: 1px;
    margin: 0;
}

html, body, header, .view {
	  height: 100%;
}

.navbar-light.scrolled {
	background-color: #2f353e !important;
	transition: background-color 200ms linear;
}

.navbar-light.scrolled a.nav-link {
 	color: white;
}

.title {
  	 font-family: Dosis, Nunito, 'Open Sans', 'Encode Sans', 'Nunito', sans-serif;
  	 letter-spacing: .5rem;
  	 font-weight: 600;
  	 line-height: 1.4;
}

.linkable {
	cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Open Sans", "Roboto", "Montserrat", "Encode Sans";
	text-transform: uppercase;
}

.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.links > a, .links > a:visited{
    color: white; //#636b6f;
    padding: 0 20px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

 .links > a:hover  {
 	opacity: .7;
 	color: white;
 }

 .no-underline {
 	text-decoration: none !important;
 }

 .btn-action {
   //background-color: #577b59;
   border:none;
 }

 .a-action, a-action:visited {
	color: #577b59;
	text-decoration: none !important;
 }

 .a-action:hover  {
	color: #577b59;
	opacity: .5;
	
 }

.small-gray-link {
	color: #CCC; 
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.smaller-gray {
	color: #e6e6e6; 
    font-size: 11px;
    letter-spacing: .08rem;
}

.smallest-gray {
	color: #CCC; 
    font-size: 10px !important;
    letter-spacing: .08rem;
}

.text-green {
	color: green;
}

.light-grey {
	color: #ddd;
}

.tiny-text {
	color: #666;
	font-size: 11px;
}

.error-red {
	font-size: 1rem;
	letter-spacing: 1px;
	font-family: "Nunito", "Open Sans", Tahoma;
	font-weight: bolder;
	color: red;
}

.success-green {
	font-size: 16px;
	letter-spacing: 1px;
	font-family: Nunito, "Open Sans", Tahoma;
	font-weight: bolder;
	color: green;
}

.form-text {
	font-size: 12px;
	font-family: "Open Sans", "Encode Sans", "Nunito", sans-serif;
	display: inline;
	font-weight: bolder;
	padding-left: 8px;
}

.sm-gap-input {
	margin: 5px 0 5px 0 !important;
}

.sm-gap-input .form-control {
	font-size: .7rem;
	width: 95%;
}	


// photobox

#photoBox ul {
	margin: 0 !important;
	padding: 0 !important;
	padding-inline-start: 0px !important;
}

.no-gutter > [class*='col-'] {
    padding-right:0 !important;
    padding-left:0 !important;
}

// colors 
.grey {
	color: #666 !important;
}

.grey-bordered {
	padding-bottom: 15px;
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 10px;
}

// btns

.btn-outline-orange {
	border-color: orange;
	color: orange;
}

// home

.btn-cus {
  	border-radius: 2px;
}

.btn-cus:hover {
  	background-color: white;
  	color: black !important;
}

.fa {
  	color: white;
}

label {
  	font-size: .7rem;
  	font-weight: bolder;
}

// comments formatting

.section-panel {
	border: 1px dotted #CCC;
	padding: 30px;
	margin: 20px;
}

.comment-section {
	margin-top:25px;
	border-top: 1px dotted #666;
	padding: 10px;
	letter-spacing: 1px;
}

.comment-title {
	font-weight: bolder;
	padding-left: 10px;
}

.sub-comment {
    margin-right: 4%;
    margin-bottom: 5px;
    float: right;
    font-size: smaller;
    letter-spacing: 1px;
}

.sub-comment-section {
	margin-left: 5%;
	width: 95%;
	border-top: 2px dotted #666;
	padding-top:10px;
	margin-top:15px;
}

// tabs

#description .title {
	font-size: 14px;
	font-weight: 600;
	letter-spacing: .1rem;
	font-family: 'Open Sans', Tahoma, 'Nunito';
}

.card-text {
	margin-bottom: .15rem;
}


.card-body {
	padding: 15px;
	border-radius: 15px;
}

.tab-container {
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-radius: 5px;
	background-color: white;
	min-height: 265px;
}

a.active_tabs.tabs__link:focus, a.active_tabs.tabs__link:hover {
	border-top: 3px solid orange !important;
	font-weight: 600;
	font-size: larger;
}

.vue-tabs .nav-tabs > li > a.active_tab {
	border-top: 3px solid orange !important;
}

// icons

  .ibox {
  	display: inline-block !important;
  	height: 90px;
  	width: 130px;
  	padding-bottom: 4px;
  	position: relative;
  }

  .iaction {
  	font-size: 47px;
  }

  .icon-caption {
  	position: absolute;
  	bottom: 0;
  	color: #202b12;
  	font-size: 10px;
	width: 100%;
	left: 50%;
	margin-left: -50px;
  }

  .ibox a:hover {
  	margin-top: 3px;
  	border-bottom: 3px solid orange;
  	text-decoration: none;
  }

  .d-inline-block {
  	 position: relative;
  }

// photo

.widest {
	width: 100%;
}

.thumb-fill {
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.fill-photo {
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #CCC;
    padding-top: 40%;
    width: 100%;
}

.fill-photo .over-card {
	position: absolute;
	top: 30%;
	left: 5%;
    background: rgb(80, 80, 80); /* Fallback for older browsers without RGBA-support */
    background: rgba(80, 80, 80, 0.6);
	border: 1px solid #666;
	color: white;
	font-family: "Open Sans", 'Nunito', Tahoma;
	border-radius: 4px;
}

.low-align {
	position: absolute;
	top: -50px;
	right: 50px;
}

@media screen and (max-width: 690px) {
  .over-card { display: none; }  
  .card-text { margin-bottom: .1rem; }
}

@media screen and (max-width: 990px) {
  .navbar-nav .nav-link { margin-left: 10px !important; }
}

.fill-photo .over-title {
	color: white;
	font-weight: 600;
	font-family: "Open Sans", 'Nunito', 'Dosis', Tahoma;
	letter-spacing: 5px;
	text-transform: uppercase;
	position: absolute;
	top: 35px;
	left: 7%;
	text-shadow: 1px 0px 1px black; 
}

#photoBox {
	background: transparent;
}

.header-image {
	max-height: 450px;
	overflow: hidden;
}

// other trail page


.btn-cus {
	background-color: none; //#7d5f52; // #6c8219; //#34421E;
	color: white;
	border-color: #7d5f52; // #6c8219;
}

.btn-cus-alt {
	background-color: #34421E;
	color: white;
}

.small-bold {
	color:orange;
	font-size: smaller;
	font-weight: bolder;
}

.search-linear {
   width:80px;
   float:left;
   margin-left:20px;
}

.trail-container {
  display: block;
  position: relative;
  font-family: "Nunito", "Open Sans", Tahoma;
}

#bottom {
	width:100%;
	padding-top:30px;
}

.grid-white {
	margin:0 auto;
	width: 70%;
	border-collapse: collapse;
}

table.grid-white td  {
	border-width: 0px;
	width:30%;
	padding:2px 10px 0 30px;
	font-family: "Nunito", "Open Sans", sans-serif, Tahoma;
	color: white;
	font-size:24px;
	font-weight:400;
	//background-color: #5E5E4E;
}

table.grid-white td a {
    font-family: "Nunito", "Open Sans", sans-serif, Tahoma;
	color: white;
	font-size:18px;
	font-weight:400;
}

.topo-back {
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	height: 100%;
	background-image: linear-gradient(rgba(39,73,98,0.4), rgba(39,73,98,0.4)), url("/images/topo-2.png");
}

.opacity-8 {
	opacity: .8;
}
.opacity-7 {
	opacity: .7;
}
.opacity-6 {
	opacity: .6;
}

.highlight-text {
    font-family: 'Nunito', 'Encode Sans', 'Open Sans', 'Dosis', Tahoma;
	font-weight: 400;
	font-size: 16px;
	//font-weight: 600;
	letter-spacing: 1.3px;
	line-height: 1.9;
	text-align: center;
}

.invisible {
	visibility: hidden;
}

// spinner (near me)

.loading {
    display: flex;
    justify-content: center;
}

.loading::after {
    content: "";
    width: 50px;
    height: 50px;
    border: 10px solid #dddddd;
    border-top-color: #3490dc;
    border-radius: 50%;
    animation: loading 1s ease infinite;
}

@keyframes loading {
    to {
        transform: rotate(1turn);
    }
}

.nospin {
	display: none;
}

// subscriber benefits

#features div, #features li, #features p, #features body {
	font-family: 'Encode Sans', 'Open Sans', Tahoma;
	letter-spacing: 1px;
	font-size: 1rem;
}

#features table td, #features table th {
	font-family: 'Encode Sans', 'Open Sans', Tahoma;
	font-weight: 600;
	font-size: 16px;
	border: 1px solid #e6e6e6;
	text-align: center;
}

#features table th {
	background-color: #e6e6e6;
}









